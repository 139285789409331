<template>
    <router-view v-slot="{ Component, route }">
        <keep-alive>
            <suspense>
                <template #default>
                    <component
                        :is="Component"
                        :key="route.meta.usePathKey ? route.path : undefined"
                    />
                </template>
                <template #fallback> 
                    <LoadingComponent />
                </template>
            </suspense>
        </keep-alive>
    </router-view>
</template>

<script>
    export default {
        name : 'home'
    }
</script>