<template>
    <div>
        <div class="product-page-header_wrap"> 
            <h1>
                <img :src="_logo" alt="Dosi-in">
            </h1>        
            <h2 class="subtitle dosiin_mt-1">
                Chào mừng <span style="color: #484848;font-weight: 600;" v-if="currentUser && currentUser.firstname"> {{currentUser.firstname}} </span>  đến với thương mại điện tử Dosiin
            </h2>    
        </div>

        <HomeGroupMenu />

        <router-view v-slot="{ Component, route }">
            <keep-alive>
                <suspense>
                    <template #default>
                        <component
                            :is="Component"
                            :key="route.meta.usePathKey ? route.path : undefined"
                        />
                        </template>
                        <template #fallback> 
                            <LoadingComponent />
                        </template>
                    </suspense>
            </keep-alive>
        </router-view>
    </div>
</template>

<script>
    import HomeGroupMenu from '@/components/menus/HomeGroupMenu';
    import { inject } from '@vue/runtime-core';
    
    export default {
        components : {
            HomeGroupMenu
        },
        setup() {
            const currentUser = inject('currentUser');

            return {
                currentUser
            }
        },
    }
</script>