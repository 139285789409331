<template>
  <nav class="sticky-nav-bar">
    <ul class="home-submenu dosiin_main-menu">
      <li class="home-submenu_item" :class="{ active: route.name == 'home-home' }">
        <router-link :to="{ name: 'home-home' }" title="Tất cả">Tất cả</router-link>
      </li>

      <li class="home-submenu_item" :class="{ active: route.name == 'ranking' }">
        <router-link :to="{ name: 'ranking', query: itms.ranking }" title="Xếp hạng"
          >Xếp hạng</router-link
        >
      </li>

      <li class="home-submenu_item" :class="{ active: route.name == 'newest' }">
        <router-link
          :to="{
            name: 'new-arrival',
            query: {
              order_by: 'timestamp',
              itm_source: 'homepage',
              itm_medium: 'menu',
              itm_campaign: 'normal',
              itm_content: 'newproduct',
            },
          }"
          title="Sản phẩm mới"
        >
          Mới nhất
        </router-link>
      </li>

      <!-- <li class="home-submenu_item">
                <a href="/dosiin-on-sale/?itm_source=homepage&itm_medium=menu&itm_campaign=normal&itm_content=giasoc" title="Mới nhất">Giá sốc</a>
            </li> -->

      <li class="home-submenu_item" :class="{ active: route.name == 'on-sale' }">
        <router-link :to="{ name: 'on-sale' }" title="Giá sốc">Giá sốc</router-link>
      </li>

      <!-- <li
        class="home-submenu_item submenu_item-event"
        :class="{ active: route.name == 'events' }"
      > -->
      <!-- <p class="firework-animation"> 
                    <img class="animation" src="https://dosi-in.com/images/assets/animation/notificantion-dot.gif" width="12" height="12" alt="animation">
                </p> -->
      <!-- off notification -->
      <!-- <router-link :to="{ name: 'events' }" title="Sự kiện">Sự kiện </router-link>
      </li> -->

      <!-- <li
        class="home-submenu_item submenu_item-event"
        :class="{ active: route.name == 'musinsa' }"
      >
        <p class="firework-animation">
          <img
            class="animation"
            src="https://dosi-in.com/images/assets/animation/notificantion-dot.gif"
            width="12"
            height="12"
            alt="animation"
          />
        </p>
        <a href="https://musinsa.dosi-in.com/" target="_blank" title="Musinsa"
          >Musinsa</a
        >
      </li> -->
    </ul>
  </nav>
</template>

<script>
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const itms = {
      ranking: {
        itm_source: "homepage",
        itm_medium: "menu",
        itm_campaign: "normal",
        itm_content: "ranking",
      },
    };

    return {
      route,
      itms,
    };
  },
};
</script>

<style scoped>
.submenu_item-event .firework-animation .animation {
  height: 12px;
  width: 12px;
  position: absolute;
  right: 5%;
  top: 15%;
  padding: 0;
}
.submenu_item-event a {
  color: #fe252c !important;
}
</style>
